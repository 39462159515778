<template>
  <div style="width: 100%;height: calc(100% - 28px);overflow: hidden;">
      <div style="width: 100%;height: 100%;">
          <div id="search" class="search-container">
            <el-form :inline="true"  @submit.native.prevent>
              <el-form-item>
                <el-input v-model="searchForm.code" placeholder="代码或名称" clearable @keyup.enter.native="searHqhisList()"></el-input>
              </el-form-item>

              <el-form-item>
                <el-button @click="searHqhisList">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>

          <div id="table" class="mini-table-container">
              <!-- <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            @selection-change="handleSelectionChange">-->
            <el-table ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              :height="tableHeight"
              size="mini"
              :header-row-style="{height:'30px'}"
              :header-cell-style="{background:'#f5f7fa',padding:'0px'}"
              :row-style="{height:'20px'}"
              :cell-style="{padding:'0px'}"
              style="width: 100%"
              border
              stripe
            >
            <el-table-column label="序号" align="center" width="50">
              <template slot-scope="scope">{{ (current - 1) * size + scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column prop="code" label="代码" align="center" width="80"></el-table-column>
            <el-table-column prop="name" label="名称" align="center" width="90"></el-table-column>
            <el-table-column prop="tdate" width="120" align="center" label="时间"></el-table-column>
            <el-table-column prop="lastClosePrice" label="昨收盘" align="right" width="80">
                <template slot-scope="scope">
                    <!-- 使用全局过滤器 格式化价格 -->
                    {{ scope.row.lastClosePrice | digFmtDot2}}
                </template>
            </el-table-column>
            <el-table-column prop="openPrice" label="开盘价" align="right" width="80">
                <template slot-scope="scope">
                    <!-- 使用私有过滤器 格式化价格 -->
                    {{ scope.row.openPrice | rounding}}
                </template>
            </el-table-column>
            <el-table-column prop="closePrice" label="收盘价" align="right" width="80">
                      <template slot-scope="scope">
                    {{ scope.row.closePrice | rounding}}
                </template>
            </el-table-column>
            <el-table-column prop="minPrice" label="最低价" align="right" width="80">
                <template slot-scope="scope">
                    {{ scope.row.minPrice | rounding}}
                </template>
            </el-table-column>
            <el-table-column prop="maxPrice" label="最高价" align="right" width="80">
                <template slot-scope="scope">
                    {{ scope.row.maxPrice | rounding}}
                </template>
            </el-table-column>
            <el-table-column prop="zde" label="涨跌额" align="right" width="80">
                      <template slot-scope="scope">
                    {{ scope.row.zde | rounding}}
                </template>
            </el-table-column>
            <el-table-column prop="zdf" label="涨跌幅%" align="right" width="80">
                <template slot-scope="scope">
                <span v-if="scope.row.zdf < 0" style="color: rgb(14, 207, 30);">{{scope.row.zdf | rounding}}</span>
                <span v-else-if="scope.row.zdf >=3" style="color: rgb(248, 36, 36);">{{scope.row.zdf | rounding}}</span>
                <span v-else>{{scope.row.zdf | rounding}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="hsl" label="换手率%" align="right" width="80">
                <template slot-scope="scope">
                    {{ scope.row.hsl | rounding}}
                </template>
            </el-table-column>
            <el-table-column prop="cjl" label="成交量万手" align="right" width="100" :formatter="wanFormatter"></el-table-column>
            <el-table-column prop="cjje" label="成交额万" align="right" width="110" :formatter="wanFormatter"></el-table-column>
            <el-table-column prop="cjbs" label="成交笔数" align="right" width="110"></el-table-column>
            <el-table-column prop="totalValue" label="总市值亿" align="right" width="110" :formatter="yiFormatter"></el-table-column>
            <el-table-column prop="circulationValue" label="流通市值亿" align="right" width="110" :formatter="yiFormatter"></el-table-column>

            <el-table-column prop="icon" width="70px" label="操作">
              <template slot-scope="scope">
              <el-button type="text" @click="editHandle(scope.row.id)">详情</el-button>
              </template>
            </el-table-column>
            </el-table>

            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[20, 50, 150, 300,500]"
            :current-page="current"
            :page-size="size"
            :total="total"
            ></el-pagination>
          </div>
      </div>
  </div>
  
</template>

<script>
export default {
  name: "Hqnew",
  data() {
    return {
      searchForm: {},
      // delBtlStatu: true,
      total: 0,
      defaultSize: 50,
      defaultCurrent: 1,
      size: this.defaultSize,
      current: this.defaultCurrent,
      // dialogVisible: false,
      // editForm: {
      // },
      tableData: [],
      tableHeight: undefined,
      //#region
      // editFormRules: {
      // 	username: [
      // 		{ required: true, message: '请输入用户名称', trigger: 'blur' }
      // 	],
      // 	email: [
      // 		{ required: true, message: '请输入邮箱', trigger: 'blur' }
      // 	],
      // 	statu: [
      // 		{ required: true, message: '请选择状态', trigger: 'blur' }
      // 	]
      // },

      // multipleSelection: [],

      // roleDialogFormVisible: false,
      // defaultProps: {
      // 	children: 'children',
      // 	label: 'name'
      // },
      // roleForm: {},
      // roleTreeData: [],
      // treeCheckedKeys: [],
      // checkStrictly: true
      //#endregion
    };
  },
  created() {
    this.tableHeight = window.innerHeight - 60 - 28 - 36 - 32 - 10;
    this.getHqhisList();

    // this.$axios.get("/sys/role/list").then(res => {
    // 	this.roleTreeData = res.data.data.records
    // })
  },
  filters: {
    //私有过滤器，可以用全局过滤器 在main.js -> filter.js文件中
    rounding(value) {
      if (value || value === 0) {
        //这个判断是为了防止数据为空，页面渲染为NAN
        return value.toFixed(2);
      }
    },
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val;
      this.getHqhisList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val;
      this.getHqhisList();
    },

    yiFormatter(row, column, cellValue, index) {
      //数值以 亿 为单位  Math.round(7/2)
      return Math.round(cellValue / 100000000);
    },
    wanFormatter(row, column, cellValue, index) {
      //数值以 万 为单位
      return Math.round(cellValue / 10000);
    },
    // #region
    // toggleSelection(rows) {
    // 	if (rows) {
    // 		rows.forEach(row => {
    // 			this.$refs.multipleTable.toggleRowSelection(row);
    // 		});
    // 	} else {
    // 		this.$refs.multipleTable.clearSelection();
    // 	}
    // },
    // handleSelectionChange(val) {
    // 	console.log("勾选")
    // 	console.log(val)
    // 	this.multipleSelection = val;

    // 	this.delBtlStatu = val.length == 0
    // },

    // resetForm(formName) {
    // 	this.$refs[formName].resetFields();
    // 	this.dialogVisible = false
    // 	this.editForm = {}
    // },
    // handleClose() {
    // 	this.resetForm('editForm')
    // },
    // #endregion

    searHqhisList() {
      this.current = this.defaultCurrent;
      this.size = this.defaultSize;
      this.getHqhisList();
    },

    getHqhisList() {
      this.$axios
        .get("/showwy/gphqnew", {
          params: {
            hqid: null,
            code: this.searchForm.code,
            tdateStart: null,
            tdateEnd: null,
            current: this.current,
            size: this.size
          },
        })
        .then((res) => {
          console.log("==========+++++++===========");
          console.log(res.data.data);
          console.log("==========+++++++===========");
          this.tableData = res.data.data.records;
          this.size = res.data.data.size;
          this.current = res.data.data.current;
          this.total = res.data.data.total;
        });
    },
    //#region
    // submitForm(formName) {
    // 	this.$refs[formName].validate((valid) => {
    // 		if (valid) {
    // 			this.$axios.post('/sys/user/' + (this.editForm.id ? 'update' : 'save'), this.editForm)
    // 				.then(res => {

    // 					this.$message({
    // 						showClose: true,
    // 						message: '恭喜你，操作成功',
    // 						type: 'success',
    // 						onClose: () => {
    // 							this.getHqhisList()
    // 						}
    // 					});

    // 					this.dialogVisible = false
    // 				})
    // 		} else {
    // 			console.log('error submit!!');
    // 			return false;
    // 		}
    // 	});
    // },
    // editHandle(id) {
    // 	this.$axios.get('/sys/user/info/' + id).then(res => {
    // 		this.editForm = res.data.data

    // 		this.dialogVisible = true
    // 	})
    // },
    // delHandle(id) {
    // 	var ids = []
    // 	if (id) {
    // 		ids.push(id)
    // 	} else {
    // 		this.multipleSelection.forEach(row => {
    // 			ids.push(row.id)
    // 		})
    // 	}
    // 	console.log(ids)

    // 	this.$axios.post("/sys/user/delete", ids).then(res => {
    // 		this.$message({
    // 			showClose: true,
    // 			message: '恭喜你，操作成功',
    // 			type: 'success',
    // 			onClose: () => {
    // 				this.getHqhisList()
    // 			}
    // 		});
    // 	})
    // },

    // submitRoleHandle(formName) {
    // 	var roleIds = this.$refs.roleTree.getCheckedKeys()

    // 	console.log(roleIds)

    // 	this.$axios.post('/sys/user/role/' + this.roleForm.id, roleIds).then(res => {
    // 		this.$message({
    // 			showClose: true,
    // 			message: '恭喜你，操作成功',
    // 			type: 'success',
    // 			onClose: () => {
    // 				this.getHqhisList()
    // 			}
    // 		});

    // 		this.roleDialogFormVisible = false
    // 	})
    // },
    // #endregion
  },
};
</script>

<style scoped>
/* .el-form{
		margin-top: 2px;
		margin-bottom: 2px;
		padding: 0px;
		height: 30px;
	}
	.el-form-item{
		padding: 0px;
		height: 20px;
	} */

/* #search{
		margin-top: 0;
		margin-bottom: 0;
		padding: 0px;
		height: 20px;
	} */
.el-pagination {
  float: right;
  margin-top: 5px;
  margin-bottom: 5px;
}
.search-container /deep/ .el-form-item {
  margin-bottom: 2px;
}
.search-container /deep/ .el-input__inner {
  height: 26px;
  line-height: 26px;
}
.search-container /deep/ .el-button {
  padding: 5px 12px;
}
.mini-table-container {
  width: 100%;
  height: calc(100% - 36px);
}
</style>